<template>
  <div class="gov-intro-container center">
    <div class="gov-intro column">

      <span clsas="gov-intro-title">
        <p class="gov-intro-title-text">국비교육 수강신청 방법</p>
      </span>

      <span class="gov-intro-sub-title">
        <p class="gov-intro-sub-title-text">■ 국민내일배움카드 발급 방법</p>
      </span>

      <span clsas="gov-intro-content">
        <p class="gov-intro-content-text">1) 고용센터 방문하기</p>
        <p class="gov-intro-content-text">2) 고용24를 통한 온라인 신청
        <a href="https://www.work24.go.kr" style='color : blue'>(링크)</a>
        </p>
      </span>

      <hr class="gov-intro-hr ">

      <span class="gov-intro-sub-title">
        <p class="gov-intro-sub-title-text">■ 고용24 수강신청 방법</p>
      </span>

      <span clsas="gov-intro-content">
        <p class="gov-intro-content-text">1) 고용노동부 고용24(www.work24.go.kr) 접속 후 로그인하기</p>
        <img class="gov-intro-image" :src="introProcessImage1">
	<p class="gov-intro-content-text">2) 직업 능력 개발 메뉴 – 훈련 찾기﹒신청 누르기</p>
        <img class="gov-intro-image" :src="introProcessImage2">

        <p class="gov-intro-content-text">3) 검색어에 ‘한선생직업전문학교’ 입력 후 검색하기</p>
        <img class="gov-intro-image" :src="introProcessImage3">

        <p class="gov-intro-content-text">4) 검색결과 중 배우고자 하는 과정 선정 후 과정이름 클릭하기</p>
        <img class="gov-intro-image" :src="introProcessImage4">

        <p class="gov-intro-content-text">5) 훈련과정 보기 페이지에서 [수강신청] 버튼 클릭하기</p>
        <img class="gov-intro-image" :src="introProcessImage5">

        <p class="gov-intro-content-text">6) 신청정보, 신청절차, 유의사항 확인 후 수강신청 진행</p>

      </span>


    </div>
  </div>
</template>

<script>
import introProcessImage1 from '../../assets/images/intro_process_image_1.png'
import introProcessImage2 from '../../assets/images/intro_process_image_2.png'
import introProcessImage3 from '../../assets/images/intro_process_image_3.png'
import introProcessImage4 from '../../assets/images/intro_process_image_4.png'
import introProcessImage5 from '../../assets/images/intro_process_image_5.png'


export default {
  data() {
    return {
      introProcessImage1,
      introProcessImage2,
      introProcessImage3,
      introProcessImage4,
      introProcessImage5
    }
  }
}
</script>

<style>

</style>
